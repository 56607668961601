import React from "react"
// import AnchorLink from "react-anchor-link-smooth-scroll"
import { Container } from "components/common"
import dev from "assets/illustrations/skills3.svg"
import { Wrapper, SkillsWrapper, Details, Thumbnail } from "./styles"
// import data from "../../../data/chart.js"

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const Skills = () => {
  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img
            src={dev}
            alt="I’m Cale Crawford and I’m a fullstack developer with a focus on architecture!"
          />
        </Thumbnail>
        <Details>
          <h1>My story</h1>
          <p>
            I am an experienced software architect/developer working to create
            and maintain enterprise scale applications. I specialize in
            JavaScript, Ruby, and MongoDB. While in a previous career in
            television editing (2011-2013) I began freelancing for a local
            development company helping them migrate from Cold Fusion to JQuery.
          </p>
          <p>
            Shortly after in 2013 I was given a full time position by RoaringSky
            to lead a team in the direction of the modern workforce. I helped
            progress said team to work from JQuery > Angular JS > Vue JS and in
            the parallel, helped to guide said team from Sinatra > Grape >
            Rails.
          </p>
          <p>
            My method is to learn, process, hone, and achieve desired results by
            example as well as team-building. Having a background in accesibilty
            has given me insight into what can can be done to elevate and
            advance an experience for all who utilize it. I lean toward picking
            a technology for its exact strength, resulting in less technical
            debt, and a prosperous outcome.
          </p>
          {/* <Button as={AnchorLink} href="#contact">
            Hire me
          </Button> */}
        </Details>
      </SkillsWrapper>
    </Wrapper>
  )
}

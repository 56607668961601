import styled from "styled-components"

// background-image: url(${detailsIllustration});

export const Wrapper = styled.div`
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`

export const ChartWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const ChartLayout = styled.div`
  height: 500px;
  width: 100%;
`

import React from "react"
// import AnchorLink from "react-anchor-link-smooth-scroll"
import { Container } from "components/common"
// import dev from "assets/illustrations/skills3.svg"
import { Wrapper, ChartWrapper, ChartLayout } from "./styles"
import { ResponsiveBar } from "@nivo/bar"

export const Chart = () => {
  const chartData = [
    {
      language: "Vue",
      Vue: 95,
      VueColor: "hsl(160, 100%, 37%)",
    },
    {
      language: "JavaScript",
      JavaScript: 88,
      JavaScriptColor: "hsl(53, 100%, 48%)",
    },
    {
      language: "Ruby/Ruby On Rails",
      "Ruby/Ruby On Rails": 80,
      "Ruby/Ruby On RailsColor": "hsl(359, 78%, 49%)",
    },
    {
      language: "React",
      React: 45,
      ReactColor: "hsl(188, 100%, 50%)",
    },
    {
      language: "Node",
      Node: 65,
      NodeColor: "hsl(120, 100%, 22%)",
    },
    {
      language: "MongoDB",
      MongoDB: 77,
      MongoDBColor: "hsl(144, 100%, 34%)",
    },
  ]
  return (
    <Wrapper id="chart">
      <ChartWrapper as={Container}>
        <ChartLayout>
          <ResponsiveBar
            data={chartData}
            keys={[
              "Vue",
              "JavaScript",
              "Ruby/Ruby On Rails",
              "React",
              "Node",
              "MongoDB",
            ]}
            indexBy="language"
            margin={{ top: 50, right: 150, bottom: 50, left: 150 }}
            padding={0.3}
            layout="horizontal"
            colors={[
              "hsl(160, 100%, 37%)",
              "hsl(53, 100%, 48%)",
              "hsl(359, 78%, 49%)",
              "hsl(188, 100%, 50%)",
              "hsl(120, 100%, 22%)",
              "hsl(144, 100%, 34%)",
            ]}
            colorBy="index"
            defs={
              [
                // {
                //   id: "dots",
                //   type: "patternDots",
                //   background: "inherit",
                //   color: "#38bcb2",
                //   size: 4,
                //   padding: 1,
                //   stagger: true,
                // },
                // {
                //   id: "lines",
                //   type: "patternLines",
                //   background: "inherit",
                //   color: "#eed312",
                //   rotation: -45,
                //   lineWidth: 6,
                //   spacing: 10,
                // },
              ]
            }
            fill={
              [
                // {
                //   match: {
                //     id: "Node",
                //   },
                //   id: "dots",
                // },
                // {
                //   match: {
                //     id: "sandwich",
                //   },
                //   id: "lines",
                // },
              ]
            }
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Percent mastery",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            // axisLeft={{
            //   tickSize: 5,
            //   tickPadding: 5,
            //   tickRotation: 0,
            //   legend: "food",
            //   legendPosition: "middle",
            //   legendOffset: -40,
            // }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </ChartLayout>
      </ChartWrapper>
    </Wrapper>
  )
}

import React from "react"
// import { Link } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"
import { Layout, SEO } from "./../components/common"
import { Intro, Skills, Chart, Contact } from "./../components/landing"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Intro />
    <Skills />
    <Chart />
    <Contact />
  </Layout>
)

export default IndexPage
